.App {
  text-align: center;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgb(4 120 87);
  background-color: #fff;
  border: 1px solid #dee2e6
}

.page-link:hover {
  color: rgb(4 120 87);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25)
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: rgb(4 120 87);
  border-color: rgb(4 120 87)
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}


.modal::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.modal {
  max-width: 950px;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  position: relative;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
}

.modal-close-btn {
  font-size: 1.5em;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  border-color: #f3f3f3;
  border-radius: 0rem;
}

.modal-close-btn:hover {
  background: #f3f3f3;
}

.modal-viewport{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  z-index: 9000
}

.modal-header {
  position: relative;
  padding: 20px 20px 0 20px
}

.modal-body {
  padding: 10px 20px;
  font-size: 0.875em;
}